<template>
    <div :style="style" ref="lavContainer"></div>
</template>

<script>
  import lottie from 'lottie-web';
  export default {
    props: {
      options: {
        type: Object,
        required: true
      },
      height: Number,
      width: Number,
      changeEndStop: Boolean
    },
    data () {
      return {
        style: {
          width: this.width ? `${this.width}px` : '100%',
          height: this.height ? `${this.height}px` : '100%',
          overflow: 'hidden',
          margin: '0 auto',
          
        }
      }
    },
    mounted () {
      this.anim = lottie.loadAnimation({
          container: this.$refs.lavContainer,
          renderer: 'svg',
          loop: this.options.loop !== false,
          autoplay: this.options.autoplay !== false,
          initialSegment: this.options.initialSegment,
          animationData: this.options.animationData,
          rendererSettings: this.options.rendererSettings
        }
      );
      this.$emit('animCreated', this.anim)
    },
    watch: {
      changeEndStop: function () {
        this.anim.playSegments(this.options.initialSegment, true)
        this.anim.addEventListener('loopComplete', ()=>{
          this.anim.playSegments([this.options.initialSegment[1]-1, this.options.initialSegment[1]], true)
          this.anim.pause()
        })
        // this.anim.goToAndStop(this.options.initialSegment[0], false)
      },
    }
  }
</script>